import { useSelector, useDispatch } from "react-redux";
import { fetchPopulations } from "../features/popuSlice";
import React, { useEffect } from "react";
import { fetchDiff } from "../features/popuDiffSlice";
import { saveCity } from "../features/popuSlice";
import { fetchCities } from "../features/citiesSlice";
import { fetchStudents } from "../features/studentSlice";



const CitySelect = () => {
    const cities = useSelector((state) => state.cities.cities)
    const landing = useSelector((state) => state.global.landing)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCities())
    }, [landing])

    const selectCity = (e) => {
        console.log(e.target.value)
        dispatch(fetchPopulations(e.target.value))
        dispatch(fetchDiff(e.target.value))
        dispatch(fetchStudents(e.target.value))
        dispatch(saveCity(e.target.value))
    }

    return (
        <select className="form-select" aria-label="Default select example" onChange={selectCity}>
            {cities.map(item => {
                if (item === 'All') {
                    return (<option value='All' selected>總覽</option>)
                } else {
                    return (<option value={item}>{item}</option>)
                }
            })}

        </select>
    )
}

export default CitySelect;