import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import InnerTable from './InnerTable';


const MyLineChart = ({ labelName, data, lineName, title, tableData }) => {

    const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"]
    const [showDetail, setShowDetail] = useState(false)

    return (
        <div className="card border-success mb-2">
            <div className="card-header text-bg-success">
                {title}
            </div>
            <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 10,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray={`${lineName.length} ${lineName.length}`} />
                        <XAxis dataKey={labelName} />
                        <YAxis tickCount={10} />
                        <Tooltip />
                        <Legend />
                        {lineName.map(element => {
                            return (<Line type="monotone" dataKey={element.value}
                                stroke={COLORS[element.key % COLORS.length]} />)
                        })}
                    </LineChart>
                </ResponsiveContainer>
                {<div className="form-check form-switch my-1">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDeault" onChange={() => setShowDetail(!showDetail)} />
                    <label className="form-check-label" for="flexSwitchCheckDeault">Show detail</label>
                </div>}

                {showDetail && <InnerTable data={tableData} />}
            </div>
        </div>
    );

}
export default MyLineChart;
