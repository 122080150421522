import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASEURL } from "./constants";
import axios from "axios";

const initialState = {
    loading: false,
    interval: 5,
    popu_raw: [],
    labor_raw: [],
    popu: [],
    labor: [],
    error: ''
}

export const fetchAgeData = createAsyncThunk('fetchAgeData', (city) => {
    return axios.all([
        axios.get(`${BASEURL}/data?type=age&filter_key=City&filter_value=${city}`),
        axios.get(`${BASEURL}/data?type=labor&sub_key=age`),
    ]).then(axios.spread((age, labor) => {
        return [age.data, labor.data]
    }))
})

const getPopu = (rawData, interval) => {
    let res = { lineName: [], data: [], tableData: [] }
    let male = rawData.filter(element => element.Gender === 'M').map(element => element)[0]
    let female = rawData.filter(element => element.Gender === 'F').map(element => element)[0]
    res['tableData'].push(['#', 'Male', 'Female'])
    let start = interval == 5 ? 15 : 20
    while (start < 70) {
        let dataPoint = { name: start, Male: 0, Female: 0 }
        let endYear = start + interval
        if (interval == 5 && start === 65) {
            endYear = 101
        } else if (interval > 5 && start === 60) {
            endYear = 101
        }
        for (let year = start; year < endYear; year++) {
            dataPoint['Male'] += Number(male[year])
            dataPoint['Female'] += Number(female[year])
        }
        res['data'].push(dataPoint)
        res['tableData'].push([start, dataPoint['Male'], dataPoint['Female']])
        start += interval
    }
    res['lineName'].push({ key: 1, value: 'Male' })
    res['lineName'].push({ key: 2, value: 'Female' })
    return res
}


const getLabor = (rawData, interval) => {
    let res = { lineName: [], data: [], tableData: [] }
    let employed = rawData.filter(element => element.Status === 'employed').map(element => element)[0]
    let unemployed = rawData.filter(element => element.Status === 'unemployed').map(element => element)[0]
    let nonLabor = rawData.filter(element => element.Status === 'nonLabor').map(element => element)[0]
    res['tableData'].push(['#', 'Employed', 'Unemployed', 'Non Labor'])
    let start = interval == 5 ? 15 : 20

    while (start < 70) {
        let dataPoint = { name: start, Employed: 0, Unemployed: 0, 'Non Labor': 0 }

        for (let i = start; i < start + interval; i += 5) {
            console.log('laborl', i)
            dataPoint['Employed'] += Number(employed[i])
            dataPoint['Unemployed'] += Number(unemployed[i])
            dataPoint['Non Labor'] += Number(nonLabor[i])
        }
        res['data'].push(dataPoint)
        res['tableData'].push([start, dataPoint['Employed'], dataPoint['Unemployed'], dataPoint['Non Labor']])
        start += interval
    }

    res['lineName'].push({ key: 1, value: 'Employed' })
    res['lineName'].push({ key: 2, value: 'Unemployed' })
    res['lineName'].push({ key: 3, value: 'Non Labor' })
    return res
}

const ageSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        changeInterval: (state, action) => {
            state.interval = Number(action.payload)
            state.popu = getPopu(state.popu_raw, state.interval)
            state.labor = getLabor(state.labor_raw, state.interval)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAgeData.pending, (state) => {
            state.loading = true
            state.labor = []
            state.popu = []
        })
        builder.addCase(fetchAgeData.fulfilled, (state, action) => {
            state.loading = false
            state.popu_raw = action.payload[0]
            state.labor_raw = action.payload[1]
            state.popu = getPopu(action.payload[0], state.interval)
            state.labor = getLabor(action.payload[1], state.interval)
            state.error = ''
        })
        builder.addCase(fetchAgeData.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

export const { changeInterval } = ageSlice.actions

export default ageSlice.reducer