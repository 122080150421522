import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASEURL } from "./constants";

const initialState = {
    loading: false,
    incDiff: {},
    marDiff: {},
    error: ''
}

export const fetchDiff = createAsyncThunk('fetchDiff', (city) => {
    return axios.all([
        axios.get(`${BASEURL}/data?type=diff_inc&filter_key=City&filter_value=${city}`),
        axios.get(`${BASEURL}/data?type=diff_mar&filter_key=City&filter_value=${city}`),
    ]).then(axios.spread((resp1, resp2) => {
        return [resp1.data, resp2.data]
    }))
})

const toChart = (rawData) => {
    rawData.sort((x, y) => Number(x.Year) - Number(y.Year))
    let showKeys = Object.keys(rawData[0]).filter(key => key !== 'City').map(key => key)
    let res = { lineName: [], data: [], tableData: [] }
    res['tableData'].push(showKeys)
    rawData.forEach(element => {
        let dataPoint = {}
        let temp = []
        showKeys.forEach(key => {
            dataPoint[key] = Number(element[key])
            temp.push(Number(element[key]))
        })
        res['data'].push(dataPoint)
        res['tableData'].push(temp)
    });
    let index = 0
    showKeys.forEach(key => {
        if (key !== 'Year') {
            res['lineName'].push({ key: index, value: key })
            index += 1
        }
    })

    return res
}


const popuDiffSlice = createSlice({
    name: 'diff',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDiff.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchDiff.fulfilled, (state, action) => {
            state.loading = false
            state.incDiff = toChart(action.payload[0])
            state.marDiff = toChart(action.payload[1])
            state.error = ''
        })
        builder.addCase(fetchDiff.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

export default popuDiffSlice.reducer