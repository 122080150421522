import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import InnerTable from './InnerTable';


const MyAreaChart = ({ labelName, data, lineName, title, tableData }) => {

    const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"]

    const [showDetail, setShowDetail] = useState(false);

    return (
        <div className="card border-warning mb-2">
            <div className="card-header text-bg-warning">
                {title}
            </div>
            <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                        width="100% "
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 10,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray={`${lineName.length} ${lineName.length}`} />
                        <XAxis dataKey={labelName} />
                        <YAxis tickCount={10} />
                        <Tooltip />
                        {lineName.map(element => {
                            return (<Area type="monotone" dataKey={element.value} stackId="1"
                                stroke={COLORS[element.key % COLORS.length]}
                                fill={COLORS[element.key % COLORS.length]} />)
                        })}
                    </AreaChart>
                </ResponsiveContainer>

                <div className="form-check form-switch my-1">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => setShowDetail(!showDetail)} />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Show detail</label>
                </div>
                {showDetail && <InnerTable data={tableData} />}
            </div>
        </div >
    );
}

export default MyAreaChart;