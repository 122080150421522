import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from 'axios';
import { BASEURL } from "./constants";

const initialState = {
    loading: false,
    history: {},
    ageGroup: {},
    ageRawData: [],
    nonLaborReason: [],
    occupation: [],
    role: [],
    interval: 5,
    error: ''
}

export const fetchLabor = createAsyncThunk('fetchlabor', () => {
    return axios.all([
        axios.get(`${BASEURL}/data?type=labor&sub_key=age`),
        axios.get(`${BASEURL}/data?type=labor&sub_key=gender`),
        axios.get(`${BASEURL}/data?type=labor&sub_key=nonlabor`),
        axios.get(`${BASEURL}/data?type=labor&sub_key=role`),
        axios.get(`${BASEURL}/data?type=labor&sub_key=occupation`),
    ]).then(axios.spread((age, gender, nonlabor, role, occupation) => {
        return [age.data, gender.data, nonlabor.data, role.data, occupation.data]
    }))
})



const toHistory = (rawData) => {
    let res = { lineName: [], data: [], tableData: [] }
    let monthKeys = [... new Set(rawData.map(element => element.Month))]
    monthKeys.sort((x, y) => x - y)
    let attrArray = [['employed', 'Employed'], ['unemploye', 'Unemployed'], ['nonLabor', 'Non Labor']]
    res['tableData'].push(['#', 'Employed', 'Unemployed', 'Non Labor'])
    monthKeys.forEach(month => {
        let dataPoint = { name: month.slice(-5) }
        // console.log(month.getFullYear() +  '/' + month.getMonth())
        let tableDataRow = [month.slice(-5)]
        attrArray.forEach((x) => {
            dataPoint[x[1]] = rawData
                .filter(element => element.Month === month)
                .filter(element => element.Status === x[0])
                .map(element => Number(element.Total))[0]
            tableDataRow.push(dataPoint[x[1]])
        })
        res['data'].push(dataPoint)
        res['tableData'].push(tableDataRow)
    });
    res['lineName'].push({ key: 1, value: 'Employed' })
    res['lineName'].push({ key: 2, value: 'Unemployed' })
    res['lineName'].push({ key: 3, value: 'Non Labor' })
    console.log(res)
    return res
}

const toGroupBar = (rawData, interval) => {
    let res = { lineName: [], data: [], tableData: [] }
    let employed = rawData.filter(element => element.Status === 'employed').map(element => element)[0]
    let unemployed = rawData.filter(element => element.Status === 'unemployed').map(element => element)[0]
    let nonLabor = rawData.filter(element => element.Status === 'nonLabor').map(element => element)[0]
    let start = 15
    while (start < 70) {
        let dataPoint = { name: start, employed: 0, unemployed: 0, nonLabor: 0 }
        dataPoint['Employed'] = Number(employed[start])
        dataPoint['Unemployed'] = Number(unemployed[start])
        dataPoint['Non Labor'] = Number(nonLabor[start])
        res['data'].push(dataPoint)
        start += interval
    }
    res['lineName'].push({ key: 1, value: 'Employed' })
    res['lineName'].push({ key: 2, value: 'Unemployed' })
    res['lineName'].push({ key: 3, value: 'Non Labor' })
    return res
}

const toPie = (rawData, title) => {
    let res = { data: [], tableData: [] }
    res['tableData'].push([title, "Number"])
    Object.entries(rawData[0]).forEach((key) => {
        if (key[0].indexOf('Month') >= 0) {
            return
        }
        res['data'].push({ name: key[0], value: Number(key[1]) })
        res['tableData'].push([key[0], Number(key[1])])
    })
    return res
}

export const laborSlice = createSlice({
    name: 'labor',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchLabor.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchLabor.fulfilled, (state, action) => {
            state.loading = false
            state.ageGroup = toGroupBar(action.payload[0], state.interval)
            state.ageRawData = action.payload[0]
            state.history = toHistory(action.payload[1])
            state.nonLaborReason = toPie(action.payload[2], 'Reasons')
            state.role = toPie(action.payload[3], 'Roles')
            state.occupation = toPie(action.payload[4], 'Industries')
            state.error = ''
        })
        builder.addCase(fetchLabor.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})


export default laborSlice.reducer