import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
    landing: true,
    age: false,
    student: false,
    labor: false
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        changeState : (state, action) => {
            state.landing = false
            state.age = false
            state.student = false
            state.labor = false
            _.set(state, action.payload, true)
        }
    }

})

export const {changeState} = globalSlice.actions

export default globalSlice.reducer