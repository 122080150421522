
import React from "react";

const InfoBox = ({ title, data }) => {

    data = data ? data : [[]]

    return (
        <div className="card border-secondary mb-2">
            <div className="card-header text-bg-info">
                {title}
            </div>
            <div className="card-body text-center">
                {data.map(element => {
                    return <section>
                        <h4 class="card-title">{Intl.NumberFormat().format(element[0])}</h4>
                        <p> {element[1]} </p>
                    </section>
                })}

            </div>
        </div>
    )
}

export default InfoBox;