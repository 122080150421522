import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BASEURL } from "./constants";

const initialState = {
    loading: false,
    pieData: {},
    chartData: {},
    genderData: {},
    total: 0,
    interval: 5,
    error: '',
    currSelect: 'All'
}

export const fetchPopulations = createAsyncThunk('fetchPopu', (city) => {
    return axios
        .get(`${BASEURL}/data?type=age&filter_key=City&filter_value=${city}`)
        .then((response) => response.data)
})

const toChart = (rawData) => {
    let res = { lineName: [], data: [], tableData: [] }
    let ageKey = Object.keys(rawData[0]).filter(key => !isNaN(key)).map(key => key)
    ageKey.sort((a, b) => a - b)
    let male = rawData.filter(element => element.Gender === 'M').map(element => element)[0]
    let female = rawData.filter(element => element.Gender === 'F').map(element => element)[0]
    res['tableData'].push(['#', 'Male', 'Female', 'Sum'])
    ageKey.forEach(age => {
        let dataPoint = {}
        dataPoint['name'] = age
        dataPoint['male'] = Number(male[age])
        dataPoint['female'] = Number(female[age])
        res['data'].push(dataPoint)
        res['tableData'].push([age, Number(male[age]), Number(female[age]), Number(male[age]) + Number(female[age])])
    })
    res['lineName'].push({ key: 1, value: 'male' })
    res['lineName'].push({ key: 2, value: 'female' })

    return res
}

const toGenderChart = (rawData) => {
    let pieChart = { data: [], tableData: [] }
    let lineChart = { lineName: [], data: [], tableData: [] }
    let ageKey = Object.keys(rawData[0]).filter(key => !isNaN(key)).map(key => key)
    ageKey.sort((a, b) => a - b)
    let male = rawData.filter(element => element.Gender === 'M').map(element => element)[0]
    let female = rawData.filter(element => element.Gender === 'F').map(element => element)[0]
    lineChart['tableData'].push(['#', 'Male', 'Female', 'Sum'])
    let maleSum = 0
    let femaleSum = 0
    ageKey.forEach(age => {
        let dataPoint = {}
        dataPoint['name'] = age
        dataPoint['male'] = Number(male[age])
        dataPoint['female'] = Number(female[age])
        maleSum += Number(male[age])
        femaleSum += Number(female[age])
        lineChart['data'].push(dataPoint)
        lineChart['tableData'].push([age, Number(male[age]), Number(female[age]), Number(male[age]) + Number(female[age])])
    })
    lineChart['lineName'].push({ key: 1, value: 'male' })
    lineChart['lineName'].push({ key: 2, value: 'female' })


    pieChart['data'].push({ name: "Male", value: maleSum })
    pieChart['data'].push({ name: "Female", value: femaleSum })
    pieChart['tableData'].push(["Name", "Value"])
    pieChart['data'].forEach(element => {
        pieChart['tableData'].push([element.name, element.value])
    })

    return { lineChart: lineChart, pieChart: pieChart }

}


const toTotal = (rawData) => {
    let male = rawData
        .filter(element => element.Gender === 'M')
        .map(element => element)[0]
    let female = rawData
        .filter(element => element.Gender === 'F')
        .map(element => element)[0]
    let maleTotal = Object.keys(male)
        .filter(key => !isNaN(key))
        .map(key => Number(male[key]))
        .reduce((x, y) => x + y, 0)
    let femaleTotal = Object.keys(female)
        .filter(key => !isNaN(key))
        .map(key => Number(female[key]))
        .reduce((x, y) => x + y, 0)
    return maleTotal + femaleTotal

}

const getTotal = (data, low, upper) => {
    return Object.keys(data)
        .filter(key => !isNaN(key))
        .filter(key => low <= Number(key) && Number(key) <= upper)
        .map(key => Number(data[key]))
        .reduce((x, y) => x + y, 0)
}

const toPie = (rawData) => {
    let res = { data: [], tableData: [] }
    let male = rawData.filter(element => element.Gender === 'M').map(element => element)[0]
    let female = rawData.filter(element => element.Gender === 'F').map(element => element)[0]
    res['data'].push({ name: "Male <15", value: getTotal(male, 0, 14) })
    res['data'].push({ name: "Male 15~64", value: getTotal(male, 15, 64) })
    res['data'].push({ name: "Male >65", value: getTotal(male, 65, 101) })
    res['data'].push({ name: "Female <15", value: getTotal(female, 0, 14) })
    res['data'].push({ name: "Female 15~64", value: getTotal(female, 15, 64) })
    res['data'].push({ name: "Female >65", value: getTotal(female, 65, 101) })
    res['tableData'].push(["Name", "Value"])
    res['data'].forEach(element => {
        res['tableData'].push([element.name, element.value])
    })
    return res
}

const popuSlice = createSlice({
    name: 'population',
    initialState,
    reducers: {
        saveCity: (state, action) => {
            state.currSelect = action.payload
        },
        setInterval: (state, action) => {
            state.interval = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPopulations.pending, (state) => {
            state.loading = true
            state.total = 0
            state.chartData = {}
            state.barData = {}
        })
        builder.addCase(fetchPopulations.fulfilled, (state, action) => {
            state.loading = false
            state.chartData = toChart(action.payload)
            state.total = toTotal(action.payload)
            state.pieData = toPie(action.payload)
            state.genderData = toGenderChart(action.payload)
            state.error = ''
        })
        builder.addCase(fetchPopulations.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

export const { saveCity } = popuSlice.actions

export default popuSlice.reducer