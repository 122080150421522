import { get, has } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeInterval, fetchAgeData } from "../features/ageSlice";
import MyBarChart from "./MyBarChart";

const AgePage = () => {

    const { age } = useSelector((state) => state.global)
    const { popu, labor, loading, interval } = useSelector((state) => state.age)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAgeData('All'))
    }, [age])

    const optionChange = (e) => {
        dispatch(changeInterval(e.target.value))
    }

    return (
        <section>
            {loading && <div className="row justify-content-center mt-10">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}
            <div className="row mt-2">
                <div className="col-1">
                    Interval
                </div>
                <div className="col-11">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="5"
                            onChange={optionChange}
                            checked={interval === 5} />
                        <label className="form-check-label" for="inlineRadio1">5</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="10"
                            onChange={optionChange}
                            checked={interval === 10} />
                        <label className="form-check-label" for="inlineRadio2">10</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                            value="20"
                            onChange={optionChange}
                            checked={interval === 20} />
                        <label className="form-check-label" for="inlineRadio3">20</label>
                    </div>
                </div>
            </div>

            {<div className="row mt-2">
                <div className="col">
                    {has(popu, 'data') && has(popu, 'lineName') && <MyBarChart
                        labelName="name"
                        data={get(popu, 'data', [])}
                        lineName={get(popu, 'lineName', [])}
                        title="Group by Age"
                        tableData={get(popu, 'tableData', [])} />}
                </div>
            </div>}
            {<div className="row mt-2">
                <div className="col">
                    {has(labor, 'data') && has(labor, 'lineName') && <MyBarChart
                        labelName="name"
                        data={get(labor, 'data', [])}
                        lineName={get(labor, 'lineName', [])}
                        title="Labor Status by Age"
                        tableData={get(labor, 'tableData', [])} />}
                </div>
            </div>}
        </section>

    )
}

export default AgePage;