import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASEURL } from "./constants";
import axios from "axios";

const initialState = {
    loading: false,
    cities: [],
    error: ''
}

export const fetchCities = createAsyncThunk('fetchCity', (city) => {
    return axios
        .get(`${BASEURL}/data?type=age&group_by=City`)
        .then((response) => response.data)

})

const getCities = (data) => {
    return data.map(item => item.City)
}

const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCities.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCities.fulfilled, (state, action) => {
            state.loading = false
            state.cities = getCities(action.payload)
            state.error = ''
        })
        builder.addCase(fetchCities.rejected, (state, action) => {
            state.loading = false
            state.cities = []
            state.error = action.error.message
        })
    },
})

export default citiesSlice.reducer