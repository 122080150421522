import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import InnerTable from './InnerTable';


const MyPieChart = ({ data, title, tableData, startAngel, endAngel, cy, randNum }) => {

    const COLORS = ['#0088FE', '#E30B5C', '#00C49F', '#FFBB28', '#FF8042', "#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF", "#F870A2"]
    const [showDetail, setShowDetail] = useState(false)

    return (
        <div className="card border-success mb-2">
            <div className="card-header text-bg-success">
                {title}
            </div>
            <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart width={300} height={300}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            startAngle={startAngel}
                            endAngle={endAngel}
                            data={data}
                            cx="50%"
                            cy={cy}
                            outerRadius={80}
                            innerRadius={30}
                            paddingAngle={4}
                            fill="#8884d8"
                            label
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[(index + randNum) % COLORS.length]} />
                            ))}
                        </Pie>
                        {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
                <div className="form-check form-switch my-1">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => setShowDetail(!showDetail)} />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Show detail</label>
                </div>
                {showDetail && <InnerTable data={tableData} />}
            </div>
        </div>
    );

}
export default MyPieChart;
