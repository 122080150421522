import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASEURL } from "./constants";
import axios from "axios";

const initialState = {
    loading: false,
    elementary: {},
    juniorHigh: {},
    seniorHigh: {},
    collage: {},
    table: [],
    error: ''
}

export const fetchStudents = createAsyncThunk('fetchStudents', (city) => {
    let urls = [
        `${BASEURL}/data?type=stu&sub_key=elementary`,
        `${BASEURL}/data?type=stu&sub_key=junior_high`,
        `${BASEURL}/data?type=stu&sub_key=senior_high`,
        `${BASEURL}/data?type=stu&sub_key=collage`,
    ]

    if (city != 'All') {
        urls.forEach((url, index) => {
            url += `&filter_key=縣市名稱&filter_value=${city}`
            urls[index] = url
        })
    }

    return axios.all(
        urls.map(url => {
            return axios.get(url)
        })
    ).then(axios.spread((elementary, junior_high, senior_high, collage) => {
        return [elementary.data, junior_high.data, senior_high.data, collage.data]
    }))
})


// "女": "243" ​"學校名稱": "私立淡江高中附設國小" ​​​​"男": "245" ​​​​"縣市名稱": "新北市""
const toGenderPie = (rawData) => {
    let res = { data: [], tableData: [] }
    let index = { Male: '男', Female: '女' }
    res['tableData'].push(["Gender", "Number"])
    Object.entries(index).forEach(key => {
        let num = rawData.map(element => Number(element[key[1]])).reduce((x, y) => x + y, 0)
        res['data'].push({ name: key[0], value: num })
        res['tableData'].push([key[0], num])
    })
    return res
}

const getBasicInfo = (rawData) => {
    let res = { pieData: toGenderPie(rawData), total: 0, schools: 0 }
    res['total'] = rawData
        .map(element => Number(element['男']) + Number(element['女']))
        .reduce((x, y) => x + y, 0)
    let schools = [...new Set(rawData.map(element => element['學校名稱']))]
    res['schools'] = schools.length
    return res
}

const getFemale = (array) => {
    return array.map(element => Number(element['女']))
        .reduce((x, y) => x + y, 0)
}

const getMale = (array) => {
    return array
        .map(element => Number(element['男']))
        .reduce((x, y) => x + y, 0)
}


// 等級別: "高中/五專" "女": "28" "學校名稱": "國立華僑高級中等學校" ​​"學程名稱": "普通科"
// ​​​"日夜別名稱": "日間部" ​​​​"男": "44" ​​​"縣市名稱": "新北市"
// "女": "56" ​"學校名稱": "國立政治大學" ​​​"日間∕進修別": "日" ​​​​"男": "45"
// ​​​​"科系名稱": "教育學系" ​​​​"等級別": "博士" ​​​​"縣市名稱": "臺北市" ​​​​"體系別": "一般"
const getTable = (dataArray) => {
    let ranking = { "高中": 6, "五專": 5, "博士": 0, "碩士": 1, "學士": 2, "4+X": 4, "二技": 4, "四技": 3, "七年": 4, "二專": 5, "二年制": 4 }
    let degrees = [...new Set(dataArray.map(element => element['等級別']))]
    let res = []
    degrees.sort((x, y) => ranking[x] - ranking[y])
    res.push(['學校', ...degrees, 'Total'])

    let schools = [...new Set(dataArray.map(element => element['學校名稱']))]
    schools.forEach(school => {
        let temp = [school]
        let total = 0
        degrees.forEach(deg => {
            let number = dataArray
                .filter(element => element['學校名稱'] === school)
                .filter(element => element['等級別'] === deg)
                .map(element => Number(element['女']) + Number(element['男']))
                .reduce((x, y) => x + y, 0)
            total += number
            temp.push(number)
        })
        temp.push(total)
        if (total > 0) {
            res.push(temp)
        }
    })

    res.sort((x, y) => y.slice(-1) - x.slice(-1))
    return res
}

const getSummeryTable = (seniorHigh, collage) => {
    return { 'collage': getTable(collage), 'seniorHigh': getTable(seniorHigh) }
}

// 等級別: "高中/五專" "女": "28" "學校名稱": "國立華僑高級中等學校" ​​"學程名稱": "普通科"
// ​​​"日夜別名稱": "日間部" ​​​​"男": "44" ​​​"縣市名稱": "新北市"
const getSeniorHigh = (rawData) => {
    let progName = [...new Set(rawData.filter(element => element['等級別'] === '高中').map(element => element['學程名稱']))]
    let schools = [...new Set(rawData.map(element => element['學校名稱']))]
    let summaryPie = { data: [], tableData: [] }
    let programsPie = []
    let total = 0
    progName.forEach(prog => {
        let female = getFemale(rawData.filter(element => element['學程名稱'] === prog))
        let male = getMale(rawData.filter(element => element['學程名稱'] === prog))
        total += female
        total += male
        summaryPie['data'].push({ name: prog, value: male + female })
        let single = { data: [], tableData: [], name: prog, total: 0 }
        single['data'].push({ name: '男', value: male })
        single['data'].push({ name: '女', value: female })
        single['total'] = male + female
        programsPie.push(single)
    })

    let female = getFemale(rawData.filter(element => element['等級別'] === '五專'))
    let male = getMale(rawData.filter(element => element['等級別'] === '五專'))
    total += female
    total += male

    let single = { data: [], tableData: [], name: '五專(前三)', total: 0 }
    single['data'].push({ name: '男', value: male })
    single['data'].push({ name: '女', value: female })
    single['total'] = male + female
    programsPie.push(single)
    summaryPie['data'].push({ name: '五專(前三)', value: male + female })

    summaryPie['data'].sort((x, y) => y.value - x.value)
    summaryPie['tableData'].push(['學程名稱', 'Number'])
    summaryPie['data'].forEach((element, index) => {
        summaryPie['data'][index] = { name: `${element.name}(${(element.value / total * 100).toFixed(1)}%)`, value: element.value }
        summaryPie['tableData'].push([`${element.name}(${(element.value / total * 100).toFixed(1)}%)`, element.value])
    })

    programsPie.sort((x, y) => y.total - x.total)
    programsPie.forEach(element => {
        element['tableData'].push(["Gender", "Number"])
        element['data'].forEach(one => {
            element['tableData'].push([one.name, one.value])
        })
    })

    return { total: total, schools: schools.length, summary: summaryPie, programs: programsPie }
}

// "女": "56" ​"學校名稱": "國立政治大學" ​​​"日間∕進修別": "日" ​​​​"男": "45"
// ​​​​"科系名稱": "教育學系" ​​​​"等級別": "博士" ​​​​"縣市名稱": "臺北市" ​​​​"體系別": "一般"
const getCollage = (rawData) => {
    let programs = [...new Set(rawData.map(element => element['等級別']))]
    let schools = [...new Set(rawData.map(element => element['學校名稱']))]
    let types = [...new Set(rawData.map(element => element['體系別']))]
    let summaryPie = { data: [], tableData: [] }
    let total = 0
    programs.forEach(prog => {

        let male = getMale(rawData.filter(element => element['等級別'] === prog))
        let female = getFemale(rawData.filter(element => element['等級別'] === prog))
        total += male
        total += female
        summaryPie['data'].push({ name: prog, value: male + female })
    })
    summaryPie['data'].sort((x, y) => y.value - x.value)
    summaryPie['tableData'].push(['學程名稱', 'Number'])
    summaryPie['data'].forEach((element, index) => {
        summaryPie['data'][index] = { name: `${element.name}(${(element.value / total * 100).toFixed(1)}%)`, value: element.value }
        summaryPie['tableData'].push([`${element.name}(${(element.value / total * 100).toFixed(1)}%)`, element.value])
    })

    return { total: total, schools: schools.length, summary: summaryPie }
}

const studentSlice = createSlice({
    name: 'students',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStudents.pending, (state) => {
            state.loading = true
            state.table = []
            state.elementary = {}
            state.juniorHigh = {}
            state.seniorHigh = {}
            state.collage = {}
        })
        builder.addCase(fetchStudents.fulfilled, (state, action) => {
            state.loading = false
            state.elementary = getBasicInfo(action.payload[0])
            state.juniorHigh = getBasicInfo(action.payload[1])
            state.seniorHigh = getSeniorHigh(action.payload[2])
            state.collage = getCollage(action.payload[3])
            state.table = getSummeryTable(action.payload[2], action.payload[3])
            state.error = ''
        })
        builder.addCase(fetchStudents.rejected, (state, action) => {
            state.loading = false
            state.cities = []
            state.error = action.error.message
        })
    },
})

export default studentSlice.reducer