import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeState } from './features/globalSlice';

import './bootstrap.min.css';
import './App.css';

import AgePage from './components/AgePage';
import LaborPage from './components/LaborPage';
import LandingPage from './components/LandingPage';
import StuPage from './components/StuPage';



function App() {
  const globalState = useSelector((state) => state.global)
  const dispatch = useDispatch()

  return (
    <div className="container-xxl" data-bs-theme="dark">
      <div className="row">
        <nav className="nav nav-pills nav-justified">
          <a className={globalState.landing ? "nav-link active" : "nav-link"} onClick={() => dispatch(changeState('landing'))}>Home</a>
          <a className={globalState.student ? "nav-link active" : "nav-link"} onClick={() => dispatch(changeState('student'))}>Student</a>
          <a className={globalState.labor ? "nav-link active" : "nav-link"} onClick={() => dispatch(changeState('labor'))}>Labor</a>
          <a className={globalState.age ? "nav-link active" : "nav-link"} onClick={() => dispatch(changeState('age'))}>Age</a>
        </nav>
      </div>
      {globalState.landing && <LandingPage />}
      {globalState.student && <StuPage />}
      {globalState.labor && <LaborPage />}
      {globalState.age && <AgePage />}

    </div>
  );
}

export default App;
