import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchGrad } from "../features/gradSlice";
import { fetchStudents } from "../features/studentSlice";

import { get, has } from "lodash";
import InfoBox from "./InfoBox";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";
import MyTable from "./MyTable";



const StuPage = () => {

    const { totalChart, degreeChart } = useSelector((state) => state.grad)
    const { elementary, juniorHigh, seniorHigh, collage, table, loading } = useSelector((state) => state.stu)
    const { student } = useSelector((state) => state.global)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchGrad())
        dispatch(fetchStudents('All'))
    }, [student])

    const [showHighSchoolDetail, setShowHighSchoolDetail] = useState(false)
    const [showCollageDetail, setShowCollageDetail] = useState(false)

    return (
        <section>
            {loading && <div className="row justify-content-center mt-10">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}

            <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(elementary, 'total') && get(elementary, 'total') > 0 && <InfoBox
                        title={"Elementary Information"}
                        data={[[elementary.total, "Students"], [elementary.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(elementary, 'pieData') && <MyPieChart
                        data={get(elementary, 'pieData.data', [])}
                        title="Elementary Gender"
                        tableData={get(elementary, 'pieData.tableData', [])}
                        randNum={0}
                        startAngel={0}
                        endAngel={180}
                        cy={'65%'} />}
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(juniorHigh, 'total') && get(juniorHigh, 'total') > 0 && <InfoBox
                        title={"Junior High Student Information"}
                        data={[[juniorHigh.total, "Students"], [juniorHigh.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(juniorHigh, 'pieData') && <MyPieChart
                        data={get(juniorHigh, 'pieData.data', [])}
                        title="Junior High Student Gender"
                        tableData={get(juniorHigh, 'pieData.tableData', [])}
                        randNum={0}
                        startAngel={0}
                        endAngel={180}
                        cy={'65%'} />}
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(seniorHigh, 'total') && get(seniorHigh, 'total') > 0 && <InfoBox
                        title={"Senior High Student Information"}
                        data={[[seniorHigh.total, "Students"], [seniorHigh.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(seniorHigh, 'summary') && <MyPieChart
                        data={get(seniorHigh, 'summary.data', [])}
                        title="Senior High Student Programs"
                        tableData={get(seniorHigh, 'summary.tableData', [])}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
                {get(seniorHigh, 'programs', []).map(element => {
                    return (
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <MyPieChart
                                data={get(element, 'data', [])}
                                title={get(element, 'name')}
                                tableData={get(element, 'tableData', [])}
                                randNum={0}
                                startAngel={0}
                                endAngel={180}
                                cy={'65%'} />
                        </div>)
                })}
            </div>

            <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(collage, 'total') && get(collage, 'total') > 0 && <InfoBox
                        title={"Collage Student Information"}
                        data={[[collage.total, "Students"], [collage.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(collage, 'summary') && <MyPieChart
                        data={get(collage, 'summary.data', [])}
                        title="Collage Programs"
                        tableData={get(collage, 'summary.tableData', [])}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
            </div>

            {!loading && <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="form-check form-switch my-1">
                        <input className="form-check-input" type="checkbox" role="switch" id="seniorHighDetail" onChange={() => setShowHighSchoolDetail(!showHighSchoolDetail)} />
                        <label className="form-check-label" for="seniorHighDetail">Show high school detail</label>
                    </div>
                    {showHighSchoolDetail && has(table, 'seniorHigh') && get(table, 'seniorHigh', []).length > 0 && <MyTable data={table['seniorHigh']} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                    <div className="form-check form-switch my-1">
                        <input className="form-check-input" type="checkbox" role="switch" id="collageDetail" onChange={() => setShowCollageDetail(!showCollageDetail)} />
                        <label className="form-check-label" for="collageDetail">Show collage detail</label>
                    </div>
                    {showCollageDetail && has(table, 'collage') && get(table, 'collage', []).length > 0 && <MyTable data={table['collage']} />}
                </div>
            </div>}

            <div className="row mt-4">
                <div className="col">
                    {has(totalChart, 'data') && has(totalChart, 'lineName') && <MyLineChart
                        labelName="Year"
                        data={get(totalChart, 'data', [])}
                        lineName={get(totalChart, 'lineName', [])}
                        title="Graduation Summary"
                        tableData={get(totalChart, 'tableData')} />}
                </div>
            </div>
            {degreeChart.map(element => {
                return (
                    <div className="row mt-2">
                        <div className="col">
                            <MyLineChart
                                labelName="Year"
                                data={element.data}
                                lineName={element.lineName}
                                title={`Graduates - ${element.degree}`}
                                tableData={element.tableData} />
                        </div>
                    </div>
                )
            })}
        </section>
    )
}

export default StuPage;