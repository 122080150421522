import { get, has } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLabor } from "../features/laborSlice";
import { fetchPopulations } from "../features/popuSlice";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";

const LaborPage = () => {

    const { labor } = useSelector((state) => state.global)
    const { pieData } = useSelector((state) => state.popu)
    const { loading, history, nonLaborReason, occupation, role } = useSelector((state) => state.labor)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPopulations('All'))
        dispatch(fetchLabor())
    }, [labor])

    return (
        <section>
            {loading && <div className="row justify-content-center mt-10">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}
            <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(pieData, 'data') && <MyPieChart
                        data={get(pieData, 'data', [])}
                        title="Labor Force"
                        tableData={get(pieData, 'tableData')}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                    {has(history, 'data') && has(history, 'lineName') && <MyLineChart
                        labelName="name"
                        data={get(history, 'data', [])}
                        lineName={get(history, 'lineName', [])}
                        title="Historical labor number"
                        tableData={get(history, 'tableData', [])} />}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(role, 'data') && <MyPieChart
                        data={get(role, 'data', [])}
                        title="Rols of labors"
                        tableData={get(role, 'tableData')}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(occupation, 'data') && <MyPieChart
                        data={get(occupation, 'data', [])}
                        title="Labors work for industuries"
                        tableData={get(occupation, 'tableData')}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(nonLaborReason, 'data') && <MyPieChart
                        data={get(nonLaborReason, 'data', [])}
                        title="The reasons of non labors"
                        tableData={get(nonLaborReason, 'tableData')}
                        randNum={Math.floor(Math.random() * 10)}
                        startAngel={0}
                        endAngel={360}
                        cy={'50%'} />}
                </div>
            </div>
        </section>

    )
}

export default LaborPage;