import { get, has } from "lodash";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchDiff } from "../features/popuDiffSlice";
import { fetchPopulations } from "../features/popuSlice";
import CitySelect from "./CitySelect";
import InfoBox from "./InfoBox";
import MyAreaChart from "./MyAreaChart";
import MyLineChart from './MyLineChart';
import MyPieChart from "./MyPieChart";
import MyTable from "./MyTable";

const LandingPage = () => {

    const { chartData, total, currSelect, genderData } = useSelector((state) => state.popu)
    const { incDiff, marDiff, loading } = useSelector((state) => state.diff)
    const { elementary, juniorHigh, seniorHigh, collage, table } = useSelector((state) => state.stu)
    const landing = useSelector((state) => state.global.landing)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPopulations('All'))
        dispatch(fetchDiff('All'))
    }, [landing])

    return (
        <section>
            <div className="row mt-3">
                <div className="col">
                </div>
                <div className="col">
                </div>
                <div className="col">
                    <CitySelect />
                </div>
            </div>
            {loading && <div className="row justify-content-center mt-10">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>}
            {<div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {total > 0 && <InfoBox title={"Total Population"} data={[[total, "People"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                    {has(chartData, 'data') && has(chartData, 'lineName') && <MyAreaChart
                        labelName="name"
                        data={get(chartData, 'data', [])}
                        lineName={get(chartData, 'lineName', [])}
                        title="Distributed by Age"
                        tableData={get(chartData, 'tableData', [])} />}
                </div>
            </div>}
            {<div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {!loading && <MyPieChart
                        data={get(genderData, 'pieChart.data', [])}
                        title="Gender proportion"
                        tableData={get(genderData, 'pieChart.tableData', [])}
                        randNum={0}
                        startAngel={0}
                        endAngel={180}
                        cy={'65%'} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                    {!loading && <MyLineChart labelName="Year"
                        data={get(genderData, 'lineChart.data', [])}
                        lineName={get(genderData, 'lineChart.lineName', [])}
                        title="Distribution by Gender"
                        tableData={get(genderData, 'lineChart.tableData')} />}
                </div>

            </div>}
            {<div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {!loading && <MyLineChart labelName="Year"
                        data={get(incDiff, 'data', [])}
                        lineName={get(incDiff, 'lineName', [])}
                        title="Historical people inc/dec "
                        tableData={get(incDiff, 'tableData')} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {!loading && <MyLineChart labelName="Year"
                        data={get(marDiff, 'data', [])}
                        lineName={get(marDiff, 'lineName', [])}
                        title="Historical marriage"
                        tableData={get(marDiff, 'tableData')} />}
                </div>
            </div>}

            {currSelect !== 'All' && <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {has(elementary, 'total') && get(elementary, 'total') > 0 && <InfoBox
                        title={"Elementary Student Information"}
                        data={[[elementary.total, "Students"], [elementary.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {has(juniorHigh, 'total') && get(juniorHigh, 'total') > 0 && <InfoBox
                        title={"Junior High School Student Information"}
                        data={[[juniorHigh.total, "Students"], [juniorHigh.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {has(seniorHigh, 'total') && get(seniorHigh, 'total') > 0 && <InfoBox
                        title={"Senior High School Student Information"}
                        data={[[seniorHigh.total, "Students"], [seniorHigh.schools, "Schools"]]} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    {has(collage, 'total') && get(collage, 'total') > 0 && <InfoBox
                        title={"Collage Student Information"}
                        data={[[collage.total, "Students"], [collage.schools, "Schools"]]} />}
                </div>
            </div>}

            {currSelect !== 'All' && <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    {has(table, 'seniorHigh') && get(table, 'seniorHigh', []).length > 0 && <MyTable data={table['seniorHigh']} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                    {has(table, 'collage') && get(table, 'collage', []).length > 0 && <MyTable data={table['collage']} />}
                </div>

            </div>}
        </section>
    )
}

export default LandingPage;