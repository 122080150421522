import { configureStore } from '@reduxjs/toolkit';
import ageSlice from '../features/ageSlice';
import citiesSlice from '../features/citiesSlice';
import globalReducer from '../features/globalSlice';
import gradSlice from '../features/gradSlice';
import laborSlice from '../features/laborSlice';
import popuDiffSlice from '../features/popuDiffSlice';
import popuSlice from '../features/popuSlice';
import studentSlice from '../features/studentSlice';

export const store = configureStore({
    reducer: {
        global: globalReducer,
        popu: popuSlice,
        cities: citiesSlice,
        diff: popuDiffSlice,
        grad: gradSlice,
        labor: laborSlice,
        stu: studentSlice,
        age: ageSlice,
    }
})