import React from "react";
import InnerTable from "./InnerTable";

const MyTable = ({ data }) => {

    data = data ? data : [[]]

    return (
        <div className="card border-success mb-2 mt-3">
            <div className="card-body">
                <InnerTable data={data} />
            </div>
        </div>
    )
}

export default MyTable;