import React from "react";

const InnerTable = ({ data }) => {

    data = data ? data : [[]]

    return (
        <div className="table-responsive">
            <table className="table table-striped table-borderless">
                <thead>
                    <tr>
                        {data[0].map((element) => {
                            return (<th>{element}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.slice(1).map(element => {
                        const cells = []

                        element.forEach(cell => {
                            if (!isNaN(cell)) {
                                cell = Intl.NumberFormat().format(cell)
                            }
                            cells.push(<td>{cell}</td>)
                        })

                        return <tr>{cells}</tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default InnerTable;