import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { has } from 'lodash';
import { BASEURL } from "./constants";


const initialState = {
    loading: false,
    totalChart: {},
    degreeChart: [],
    error: ''
}

export const fetchGrad = createAsyncThunk('fetchGrad', () => {
    return axios
        .get(`${BASEURL}/data?type=grad`)
        .then((response) => response.data)
})


const toTotalChart = (rawData) => {
    let years = [...new Set(rawData.map(element => element.Year))]
    let degrees = [...new Set(rawData.map(element => element.Degree))]
    let res = { data: [], lineName: [], tableData: [] }
    res['tableData'].push(['#', ...degrees])
    years.sort((x, y) => Number(x) - Number(y))
    years.forEach(year => {
        let dataPoint = { Year: year }
        let temp = [year]
        degrees.forEach(degree => {
            let number = rawData
                .filter(element => element.Year === year)
                .filter(element => element.Degree === degree)
                .map(element => Number(element.Number))
                .reduce((x, y) => x + y, 0)
            dataPoint[degree] = number
            temp.push(number)
        })
        res['data'].push(dataPoint)
        res['tableData'].push(temp)
    });
    let index = 0
    degrees.forEach(degree => {
        res['lineName'].push({ key: index, value: degree })
        index += 1
    })

    return res
}

const toDegreeChart = (rawData) => {
    let years = [...new Set(rawData.map(element => element.Year))]
    let degrees = [...new Set(rawData.map(element => element.Degree))]
    years.sort((x, y) => Number(x) - Number(y))
    let majors = [...new Set(rawData.filter(element => element.Year === years.at(-1)).map(element => element.Major))]

    let res = []
    degrees.forEach(degree => {
        let degreeData = { data: [], lineName: [], degree: degree, tableData: [] }
        let majorForShow = new Set()
        degreeData['tableData'].push(['#', ...years])
        let temp = {}
        years.forEach(year => {
            let dataPoint = { Year: year }
            majors.forEach(major => {
                let number = rawData
                    .filter(element => element.Year === year)
                    .filter(element => element.Degree === degree)
                    .filter(element => element.Major === major)
                    .map(element => Number(element.Number))
                    .reduce((x, y) => x + y, 0)
                if (number > 0) {
                    dataPoint[major] = number
                    majorForShow.add(major)
                }
                if (!has(temp, major)) {
                    temp[major] = [major]
                }
                temp[major].push(number)
            })
            degreeData['data'].push(dataPoint)
        })
        let index = 0
        majorForShow = [...majorForShow]
        majorForShow.sort((x, y) => degreeData['data'].at(-1)[y] - degreeData['data'].at(-1)[x])
        majorForShow.forEach(major => {
            degreeData['lineName'].push({ key: index, value: major })
            index += 1
            degreeData['tableData'].push(temp[major])
        })
        res.push(degreeData)
    })

    return res
}


const gradSlice = createSlice({
    name: 'grad',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchGrad.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGrad.fulfilled, (state, action) => {
            state.loading = false
            state.totalChart = toTotalChart(action.payload)
            state.degreeChart = toDegreeChart(action.payload)
            state.error = ''
        })
        builder.addCase(fetchGrad.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

export default gradSlice.reducer